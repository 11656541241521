// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-challengecards-js": () => import("./../src/pages/challengecards.js" /* webpackChunkName: "component---src-pages-challengecards-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-datasecurity-js": () => import("./../src/pages/datasecurity.js" /* webpackChunkName: "component---src-pages-datasecurity-js" */),
  "component---src-pages-digital-transformation-overview-js": () => import("./../src/pages/digital-transformation-overview.js" /* webpackChunkName: "component---src-pages-digital-transformation-overview-js" */),
  "component---src-pages-digitalchallenge-item-page-js": () => import("./../src/pages/digitalchallenge-item-page.js" /* webpackChunkName: "component---src-pages-digitalchallenge-item-page-js" */),
  "component---src-pages-digitalcheck-js": () => import("./../src/pages/digitalcheck.js" /* webpackChunkName: "component---src-pages-digitalcheck-js" */),
  "component---src-pages-digitalefficiency-js": () => import("./../src/pages/digitalefficiency.js" /* webpackChunkName: "component---src-pages-digitalefficiency-js" */),
  "component---src-pages-experience-item-page-js": () => import("./../src/pages/experience-item-page.js" /* webpackChunkName: "component---src-pages-experience-item-page-js" */),
  "component---src-pages-experiences-js": () => import("./../src/pages/experiences.js" /* webpackChunkName: "component---src-pages-experiences-js" */),
  "component---src-pages-impressum-js": () => import("./../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-philosophy-js": () => import("./../src/pages/philosophy.js" /* webpackChunkName: "component---src-pages-philosophy-js" */),
  "component---src-pages-portfolio-item-page-js": () => import("./../src/pages/portfolio-item-page.js" /* webpackChunkName: "component---src-pages-portfolio-item-page-js" */),
  "component---src-pages-portfolio-js": () => import("./../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-profile-item-page-js": () => import("./../src/pages/profile-item-page.js" /* webpackChunkName: "component---src-pages-profile-item-page-js" */),
  "component---src-pages-team-js": () => import("./../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

