/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import './src/styles/global.css'

import muiRootWrapper from "./mui-root-wrapper"
import { ReplayOutlined } from '@material-ui/icons'
export const wrapRootElement = muiRootWrapper

export const onRouteUpdate = ({ location, prevLocation }) => {
    if (location.pathname === '/digitalcheck' && prevLocation !== '/digitalcheck' && prevLocation !== null) {
       window.location.reload()
    }
  }