import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

import { red, green } from '@material-ui/core/colors';



const theme = responsiveFontSizes(createMuiTheme({
  palette: {
    primary: {
      main: '#203864',
      menu: '#000000',
    },
    secondary: green,
    error: red,
    background: {
      main: '#ffffff',
      menu: '#DAE3F3',
      offWhite: '#ffffff80'
    },
  },
  typography: {
    fontFamily: ["'Andale mono'"].join(','),
  },
}))


export default theme;